import React from 'react';
import "./contact.css"
import Mail from "../../assets/mail.png";
import Whatsapp from "../../assets/whatsapp.png";

const Contact = () => {  
  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">O SI PREFIERES CONTACTANOS</h2>
      <div class="about4-border"></div>
      <span className="section__subtitle"></span>

      <div className="contact__container container grid">
        <div className="contact__img"></div>

        <div className="contact__content">
          <div className="contact__indo">
            <div className="contact__card">
              <a href="mailto:ventas@servicloud.com.mx" target="_blank" rel='noreferrer noopener'><img src={Mail} alt="" className='contact__card-icon' /></a>

              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">ventas@servicloud.com.mx</span>

              <a
                href="mailto:ventas@servicloud.com.mx"
                className="contact__button"
              >
                Escribenos{" "}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>

            <div className="contact__card">
              <a href="https://api.whatsapp.com/send?phone=5524446196&text=Hola, me podria dar mas informes.!" target="_blank" rel='noreferrer noopener'><img src={Whatsapp} alt="" className='contact__card-icon' /></a>

              <h3 className="contact__card-title">Whatsapp</h3>
              

              <a
                href="https://api.whatsapp.com/send?phone=5524446196&text=Hola, me podria dar mas informes.!"
                className="contact__button" target="_blank" rel='noreferrer noopener'
              >
                Escribenos{" "}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default Contact;
