import React, { useState } from 'react'
import "./header.css"
import Logo_ok from "./../../assets/logo.png"

const Header = () => {
    window.addEventListener("scroll", function(){
        const header = document.querySelector(".header");
        if (this.scrollY >= 80) header.classList.add("scroll-header");
        else header.classList.remove("scroll-header");
    });
  const[Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState("#home");
  
  return (
    <header className='header'>
        <nav className='nav container'>
            <a href="index.html" className="nav__logo"><img src={Logo_ok} alt="" className='logo__ok' />&nbsp;ServiCloud<span class="copy-left2">&reg;</span></a>

            <div className={Toggle ? "nav__menu show-menu" : "nav__menu" }>
                <ul className="nav__list grid">
                    <li className="nav__item">
                    <a href="#home" onClick={() => setActiveNav('#home')} className={activeNav === "#home" ? "nav__link active-link" : "nav__link"}>
                            <i className="uil uil-home-alt nav__icon"></i> Home
                        </a>
                    </li>

                    <li className="nav__item">
                    <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === "#about" ? "nav__link active-link" : "nav__link" }>
                            <i className="uil uil-user nav__icon"></i> Nosotros
                        </a>
                    </li>

                    <li className="nav__item">
                    <a href="#about2" onClick={() => setActiveNav('#about2')} className={activeNav === "#about2" ? "nav__link active-link" : "nav__link" }>
                            <i className="uil uil-briefcase-alt nav__icon"></i> Servicios
                        </a>
                    </li>

                    <li className="nav__item">
                    <a href="#reports" onClick={() => setActiveNav('#reports')} className={activeNav === "#reports" ? "nav__link active-link" : "nav__link" }>
                            <i className="uil uil-file-graph nav__icon"></i> Reporte
                        </a>
                    </li>

                    <li className="nav__item">
                    <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === "#contact" ? "nav__link active-link" : "nav__link" }>
                            <i className="uil uil-message nav__icon"></i> Contacto
                        </a>
                    </li>
                </ul>
                <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
            </div>

            <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                <i className="uil uil-apps"></i>
            </div>
        </nav>
    </header>
  )
}

export default Header