import React, { useState } from 'react'
import "./footeer.css";


const Footeer = () => {
    const [toggleState, setToggleState] = useState(0);

      const toggleTab = (index) =>{
          setToggleState(index);
      }
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">ServiCloud<span class="copy-left">&reg;</span></h1>
        <ul className="footer__list">
         
          <li>

            <span className="footer__link" onClick={() => toggleTab(1)}>Acerca de nosotros </span>
            <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

                        <h3 className="services__modal-title">Acerca de nosotros</h3>
                        <p className="services__modal-description">Servicloud es una compañía dedicada a facilitar servicios de mantenimientos preventivos y correctivos a equipos de cómputo que ya han cubierto su etapa de vida y garantía inicial, contando con Ingenieros y refacciones para garantizar una operación óptima de los equipos contratados</p>
                    </div>
                </div>
          </li>
          <li>
            <span className="footer__link" onClick={() => toggleTab(2)}>Privacidad </span>
            <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

                        <h3 className="services__modal-title">Aviso de Privacidad</h3>
                        <p className="services__modal-description">
<b>DEFINICIONES:</b><br/>
*Datos Personales: Cualquier información concerniente a una persona física identificada o identificable.<br/>
*Titular: La persona física (TITULAR) a quien identifica o corresponden los datos personales.<br/>
*Responsable: Persona física o moral de carácter privado que decide sobre el tratamiento de los datos personales.<br/>
*Tratamiento: La obtención, uso (que incluye el acceso, manejo, aprovechamiento, transferencia o disposición de datos personales), divulgación o almacenamiento de datos personales por cualquier medio.<br/>
*Transferencia: Toda comunicación de datos realizada a persona distinta del responsable o encargado del tratamiento.<br/>
*Derechos ARCO: Derechos de acceso, rectificación, cancelación y oposición.<br/>
*Consentimiento Tácito: Se entenderá que el titular ha consentido en el tratamiento de los datos, cuando habiéndose puesto a su disposición el Aviso de Privacidad, no manifieste su oposición.<br/>
*Finalidades Primarias.- Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita: Fines fiscales, Fines económicos y Fines laborales.<br/>
<br/>
<b>FORMAS DE RECABAR SUS DATOS PERSONALES:</b><br/>
Para las actividades señaladas en el presente aviso de privacidad, podemos recabar sus datos personales de distintas formas cuando usted nos los proporciona directamente; cuando visita nuestro sitio de Internet o utiliza nuestros servicios en línea, y cuando obtenemos información a través de otras fuentes que están permitidas por la ley.

<br/><br/>
<b>DATOS PERSONALES QUE SE RECABAN DE FORMA DIRECTA:</b><br/>
Recabamos sus datos personales de forma directa cuando usted mismo nos los proporciona por diversos medios. Tales como:<br/>
Correo electrónico
Nombre completo
Genero Edad Nacionalidad Estado civil
Nombre del cónyuge
Teléfono
Teléfono móvil
Domicilio
Fecha y lugar de nacimiento
Educación
Datos financieros Datos patrimoniales Hijos
RFC
Número de seguro social
CURP
<br/><br/>
<b>DATOS PERSONALES QUE RECABAMOS CUANDO VISITA NUESTRO SITIO DE INTERNET O UTILIZA NUESTROS SERVICIOS EN LINEA:</b><br/>
Correo electrónico<br/>
Nombre completo
<br/><br/>
<b>DATOS PERSONALES QUE RECABAMOS A TRAVÉS DE OTRAS FUENTES PERMITIDAS POR LA LEY:</b><br/>
No recabamos sus datos personales de esta forma.
<br/><br/>

<b>IMÁGENES Y SONIDOS RECABADOS POR CAMARAS DE VIDEO-VIGILANCIA:</b><br/>
Las imágenes y sonidos que se rebaben por medio de cámaras de Video-Vigilancia serán utilizados para los fines de SEGURIDAD. , tal como se especifica en el Aviso de Privacidad corto para Video-Vigilancia que podrá consultar en el área de Video- Grabación.

<br/><br/>
<b>USO DE DATOS SENSIBLES:</b><br/>
Se consideran datos sensibles aquellos afecten a la esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En el presente aviso de privacidad se omite el uso de datos personales considerados como sensibles.

<br/><br/>
<b>LIMITACIÓN O DIVULGACIÓN DE SUS DATOS PERSONALES:</b><br/>
El responsable de la información se compromete a realizar únicamente las siguientes acciones, respecto a su información:
Envió de correos electrónicos
y otros: Presentación ante autoridades fiscales y legales
<br/><br/>
<b>PROCEDIMIENTO PARA HACER VALER LOS DERECHOS ARCO:</b><br/>
Usted tiene derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando considere que no se requieren para alguna de las finalidades señalados en el presente aviso de privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado la relación contractual o de servicio, o bien, oponerse al tratamiento de los mismos para fines específicos.

Los mecanismos que se han implementado para el ejercicio de dichos derechos, los cuales se conocen como derechos Arco mismos que se refieren a la rectificación, cancelación y oposición del Titular respecto al tratamiento de sus datos personales;

El procedimiento inicia con la presentación de la solicitud respectiva a los derechos Arco, en el domicilio de nuestra Oficina de Privacidad, mismo que fue debidamente señalado al principio del presente aviso de privacidad.
Su solicitud deberá contener la siguiente información:
Nombre del titular de los datos personales
Domicilio o cualquier otro medio de contacto
El plazo para atender su solicitud es el siguiente: 20 días
<br/><br/>
<b>TRANSMISIÓN DE SUS DATOS PERSONALES:</b><br/>
Sus datos personales no pueden ser transferidos dentro y fuera del país, ni tratados por personas externas a esta empresa.

Nos comprometemos a no transferir su información personal a terceros sin su consentimiento, salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así como a realizar esta transferencia en los términos que fija esa ley.

<br/><br/>
<b>MODIFICACIONES AL AVISO DE PRIVACIDAD:</b><br/>
Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos.

Por lo que se obliga a mantener actualizado el presente aviso, para su consulta. Esto con el fin de que “EL TITULAR” se encuentre en posibilidad de ejercer sus derechos ARCO y de esta forma mantenerlo al tanto de cualquier modificación mediante aviso al último correo electrónico que nos haya proporcionado.

<br/><br/>
<b>USO DE COOKIES:</b><br/>
En el presente aviso de privacidad se omitirá el uso de cookies, para recabar información sobre usted.

<br/><br/>
<b>USO DE WEB BEACONS:</b><br/>
En el presente aviso de privacidad se omitirá el uso de web beacons, para recabar información sobre usted.

Las partes expresan que el presente aviso, se regirá por las disposiciones legales aplicables en la Republica Mexicana en especial, por lo dispuesto en la Ley Federal de Protección de Datos Personales.

Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el IFAI.</p>
                    </div>
                </div>
          </li>
          <li>
            <a href="#contact" className="footer__link" >
              Contactanos
            </a>
          </li>
        </ul>
        
        <span className="footer__copy">
        ServiCloud<span class="copy-left2">&reg;</span>. All rigths reserved
        </span>
      </div>
    </footer>
  );
};

export default Footeer;
