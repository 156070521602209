import React from 'react';
import "./duda.css";
import Data from './Data';

const Duda = () => {
  return (
    <section className="duda section" id="duda">
        <div className="duda__container container grid">
            <div className="duda__content grid">

                <div className="duda__img"></div>

                <Data/>

            </div>
            
        </div>

    </section>
  )
}

export default Duda