import React from 'react'
import Ins from "../../assets/instagram.png";
import Face from "../../assets/face.png";
import Yt from "../../assets/yt-new.png";


const Social = () => {
  return (
    <div className="home__social">
        <a href="https://www.instagram.com/" className="home__social-icon" target="_blank" rel='noreferrer'>
            <img src={Ins} alt="" className='social__icon' />
        </a>

        <a href="https://www.facebook.com/" className="home__social-icon" target="_blank" rel='noreferrer'>
            <img src={Face} alt="" className='social__icon' />
        </a>

        <a href="https://www.youtube.com/" className="home__social-icon" target="_blank" rel='noreferrer'>
            <img src={Yt} alt="" className='social__icon' />
        </a>
    </div>
  )
}

export default Social