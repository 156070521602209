import './App.css';
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
//import Services from './components/services/Services';
import Reports from './components/reports/Reports';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footeer';
import About2 from './components/about2/About2';
import Works from './components/work/Works';
import About3 from './components/about3/About3';
import Duda from './components/dudas/Duda';
import About4 from './components/about4/About4';
import About5 from './components/about5/About5';
import { FloatingWhatsApp } from "react-floating-whatsapp";
//import ScrollUp from './components/scrollup/ScrollUp';
import Logo from "./assets/logo.png";


function App() {
  return (
    <>
    <Header /> 
    <main className='main'>
      <Home/>
      <About/>
      <About2/>
      <Works/>
      <About3/>
      <Duda/>
      <About4/>
      <Reports/>
      <Contact/>
      
      
    </main>
    <FloatingWhatsApp
        phoneNumber="5524446196"
        accountName="ServiCloud"
        chatMessage="¡Hola! ¿Cómo podemos ayudarte?"
        avatar={Logo}
        allowEsc = "true"
        allowClickAway = "true"
        notification
        notificationSound
      />
    <Footer/>
    {/*<ScrollUp/>*/}
      </>
  );
}

export default App;
