import React from "react";
import "./works.css";

const Works = () => {
  return (
    <section className="works section">
      <h2 className="section__title">¿CÓMO LO HACEMOS?</h2>
      <div class="about2-border"></div>
      <span className="section__subtitle"></span>

      <div className="works__container container">
        <div className="works__sections">
        <ul class="smartart chevron vertical">
  <li>
    <div class="shape one"></div>
    <div class="text">•	Reporte por sistema de monitoreo <br></br>•	Reporte por llamada</div>
  </li>
  <li>
    <div class="shape two"></div>
    <div class="text">•	Se levanta ticket <br></br>•	Se asigna ingeniero</div>
  </li>
  <li>
    <div class="shape three"></div>
    <div class="text">•	Ingeniero hace contacto vía correo, chat y/o llamada telefónica <br></br>•	Ingeniero hace visita en sitio</div>
  </li>
  <li>
    <div class="shape four"></div>
    <div class="text">•	La falla es corregida <br></br></div>
  </li>
  <li>
    <div class="shape five"></div>
    <div class="text">•	Se entrega reporte final <br></br></div>
  </li>
</ul>

        </div>
      </div>
    </section>
  );
};

export default Works;
