import React from "react";
import "./about2.css";

const About2 = () => {
  return (
    <section className="about2 section" id="about2">
      <h2 className="section__title bb-title">¿QUÉ ES LO QUE HACEMOS?</h2>
      <div class="about2-border"></div>
      <span className="section__subtitle"></span>
      

      <div className="about2__container container grid">
      <div className="about2__img"></div>

        <div className="about2__data">
          

          <p className="about2__description">
          Brindar un servicio de mantenimiento preventivo y correctivo con el mejor 
          sistema de monitoreo local y remoto, con la mejor respuesta de los mejores 
          técnicos, con refacciones disponibles en nuestros almacenes, logrando asi 
          que los equipos que nos confían, tengan la más alta disponibilidad posible.<br></br>
          Si los años han hecho encarecer o desaparecer el mantenimiento de tus equipos, 
          <b> ServiCloud<span class="copy-left">®</span></b> es la respuesta
          </p>

          
        </div>
      </div>
    </section>
  );
};

export default About2;
