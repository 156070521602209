import React from 'react'
import Whatsapp from "../../assets/whatsapp.png";
const Data = () => {
  return (
    <div className="home__data">
        <h2 className="section__title">¿TIENES ALGUNA DUDA?
        </h2>
        <div className="home__subtitle"></div><br />
        <div className="data__center">
        <a href="https://api.whatsapp.com/send?phone=5524446196&text=Hola, me podria dar mas informes.!" target="_blank" rel='noreferrer noopener' className="button button--flex wha">
          <img src={Whatsapp} alt="" className='contact__card-icon' />
        </a>
        </div>
    </div>
  )
}

export default Data