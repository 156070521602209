import React, { useState, useRef } from "react";
import "./reports.css";
import hardwaredata from "./hardwares.json";
import emailjs from 'emailjs-com';
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

const Reports = () => {
  const captcha = useRef(null);
  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Configuración para enviar el formulario por correo electrónico
    const serviceID = 'service_mf2741r'; // Reemplaza con el ID de tu servicio de EmailJS
    const templateID = 'template_jf7364d'; // Reemplaza con el ID de tu plantilla de correo electrónico
    const userID = 'mcUJQT4COfUILBg3c'; // Reemplaza con tu User ID de EmailJS

    if(captcha.current.getValue()){
      emailjs.sendForm(serviceID, templateID, formRef.current, userID)
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          Swal.fire({
            icon: 'success',
            title: '<h2>¡Mesaje enviado con EXITO!</h2>',
            html: '<p>Responderemos lo mas pronto posible</p>',
            timer: 3000,
          })
      }, (error) => {
          console.log(error.text);
          Swal.fire({
            icon: 'error',
            title: '<h2>¡Mesaje no enviado!</h2>',
            html: '<p>'+error.text+'</p>',
            timer: 3000,
          })
      });
    } else{
      Swal.fire({
        icon: 'error',
        title: '<h2>¡Mesaje no enviado!</h2>',
        html: '<p>Por favor acepta el captcha</p>',
        timer: 3000,
      })
    }
    };

    const [marcaid, setMarcaid] = useState("");
    const [state, setState] = useState([]);
    const [modeloid, setModeloid] = useState("");

    const handlecounty = (e) => {
      const getmarcaId = e.target.value;
      const getStatedata = hardwaredata.find(
        (marca) => marca.marca_name === getmarcaId
      ).modelos;
      setState(getStatedata);
      setMarcaid(getmarcaId);
      //console.log(getmarcaId);
    };

    const handlestate = (e) => {
      const stateid = e.target.value;
      //console.log(stateid);
      setModeloid(stateid);
    };


  return (
<section className="reports section" id="reports">
      <h2 className="section__title">SELECCIONA TU EQUIPO</h2>
        <div class="about2-border"></div>
        <span className="section__subtitle"></span>
      <div className="reports__container container grid">
      <form ref={formRef} onSubmit={handleSubmit}>
          <div className="reports__form-group">
            <div className="reports__form-div">
              <label className="reports_form-tag">Nombre</label>
              <input
                type="text"
                name="name"
                className="reports__form-input"
                placeholder="Escribe tu nombre"
                required
              />
            </div>
            <div className="reports__form-div">
              <label className="reports_form-tag">Compañia</label>
              <input
                type="text"
                name="company"
                className="reports__form-input"
                placeholder="Escribe tu compañia"
                required
              />
            </div>
          </div>
          <div className="reports__form-group">
            <div className="reports__form-div">
              <label className="reports_form-tag">Correo</label>
              <input
                type="email"
                name="email"
                className="reports__form-input"
                placeholder="Escribre tu correo"
                required
              />
            </div>
            <div className="reports__form-div">
              <label className="reports_form-tag">Telefono</label>
              <input
                type="tel"
                name="tel"
                className="reports__form-input"
                placeholder="Escribre tu telefono"
                required
              />
            </div>
          </div>
          <div className="reports__form-group">
            <div className="reports__form-div">
              <label className="reports_form-tag">Marca</label>
              <select 
                name="marca"
                required
                className='reports__form-select'
                onChange={(e) => handlecounty(e)}
              >
                <option value="">--Selecciona la marca--</option>
                {hardwaredata.map((getmarca, index) => (
                  <option value={getmarca.marca_name} key={index}>
                    {getmarca.marca_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="reports__form-div">
              <label className="reports_form-tag">Modelo</label>
              <select
                name="modelo"
                required
                className='reports__form-select'
                onChange={(e) => handlestate(e)}
              >
                <option value="">--Selecciona el modelo--</option>
                {state.map((getstate, index) => (
                  <option value={getstate.modelo_name} key={index}>
                    {getstate.modelo_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="reports__form-group">
            <div className="reports__form-div">
              <label className="reports_form-tag">Información detallada de los equipos</label>
              <textarea
                name="project"
                cols="30"
                rows="10"
                className="contact__form-input"
                placeholder="Escribe"
                required
              ></textarea>
            </div>
            <div className="reports__form-div">
              <label className="reports_form-tag">Archivo con la configuración de los equipos</label>
              <input 
                type="file" 
                name="name_4n4ihps" 
                id="name_4n4ihps" 
                className="reports__form-input"
              />
            </div>
          </div>
          <div>
            <div className="reports__form-div centrar__text">
              <p><b>*Si tu equipo no aparece en la lista pregúntanos si esta soportado</b></p>
            </div>
          </div>
          <div className="reports__captcha">
            <ReCAPTCHA
              ref={captcha}
              sitekey="6LcIK2okAAAAABv1DoBGdJEYvZUz9DE4Q8GAgaFm"
            />
          </div>
          <button href="#reports" className="button button--flex">
            Enviar Información
            <svg
              className="button__icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14.2199 21.9352C13.0399 21.9352 11.3699 21.1052 10.0499 17.1352L9.32988 14.9752L7.16988 14.2552C3.20988 12.9352 2.37988 11.2652 2.37988 10.0852C2.37988 8.91525 3.20988 7.23525 7.16988 5.90525L15.6599 3.07525C17.7799 2.36525 19.5499 2.57525 20.6399 3.65525C21.7299 4.73525 21.9399 6.51525 21.2299 8.63525L18.3999 17.1252C17.0699 21.1052 15.3999 21.9352 14.2199 21.9352ZM7.63988 7.33525C4.85988 8.26525 3.86988 9.36525 3.86988 10.0852C3.86988 10.8052 4.85988 11.9052 7.63988 12.8252L10.1599 13.6652C10.3799 13.7352 10.5599 13.9152 10.6299 14.1352L11.4699 16.6552C12.3899 19.4352 13.4999 20.4252 14.2199 20.4252C14.9399 20.4252 16.0399 19.4352 16.9699 16.6552L19.7999 8.16525C20.3099 6.62525 20.2199 5.36525 19.5699 4.71525C18.9199 4.06525 17.6599 3.98525 16.1299 4.49525L7.63988 7.33525Z"
                fill="var(--container-color)"
              ></path>
              <path
                d="M10.11 14.7052C9.92005 14.7052 9.73005 14.6352 9.58005 14.4852C9.29005 14.1952 9.29005 13.7152 9.58005 13.4252L13.16 9.83518C13.45 9.54518 13.93 9.54518 14.22 9.83518C14.51 10.1252 14.51 10.6052 14.22 10.8952L10.64 14.4852C10.5 14.6352 10.3 14.7052 10.11 14.7052Z"
                fill="var(--container-color)"
              ></path>
            </svg>
          </button>
        </form>
      </div>
    </section>
  );
};

export default Reports;

