import React from "react";
import "./about4.css";

const About4 = () => {
  return (
    <section className="about4 section" id="about4">
      <h2 className="section__title bb-title">¿CUÁNTO CUESTA?</h2>
      <div class="about4-border"></div>
      <span className="section__subtitle"></span>
      

      <div className="about4__container container grid">
      <div className="about4__img"></div>

        <div className="about4__data">
          

          <p className="about4__description">
            <ul className='works__list'>
              <li>Elige la opción que más se acerque a tu equipo y pronto tendrás el estimado del mantenimiento en tu correo. </li>
              <li>O solicita a un agente de ventas y este te contactara por llamada telefónica, chat o correo electrónico. </li>
            </ul>
          </p>

          
        </div>
      </div>
    </section>
  );
};

export default About4;
