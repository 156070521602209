import React from "react";
import "./about3.css";
import Info from "./Info3";

const About3 = () => {
  return (
    <section className="about3 section" id="about3">
      <h2 className="section__title bb-title">¿QUÉ MARCAS SOPORTAMOS?</h2>
      <div class="about3-border"></div>
      <span className="section__subtitle"></span>

      <div className="about3__container container grid">
      <div className="about3__img"></div>

        <div className="about3__data">
          <Info />
        </div>
      </div>
    </section>
  );
};

export default About3;
