import React from "react";
import "./about.css";

const About = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title bb-title">¿QUÉ ES ServiCloud<span class="copy-left">&reg;</span>?</h2>
      <div class="about-border"></div>
      <span className="section__subtitle"></span>
      

      <div className="about__container container grid">
      <div className="about__img"></div>

        <div className="about__data">
          

          <p className="about__description">
          Es la solución que ayuda a que tus equipos siempre funcionen, 
          sin caídas inesperadas que te dejen sin servicio, <b>ServiCloud<span class="copy-left">&reg;</span> </b> 
           es fácil de contratar, tiene costos sorprendentes, 
          los más altos estándares técnicos y refacciones disponibles
          </p>

          
        </div>
      </div>
    </section>
  );
};

export default About;
