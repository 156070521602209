import React from 'react'
import Cisco from "../../assets/cisco.png";
import Hitachi from "../../assets/hitachi1.png";
import SM from "../../assets/sm.png";
import SO from "../../assets/oracle.png";

const Info = () => {
  return (
    <div className="about3__info grid">
        <div className="about3__box">
            <div className="about3__img1"></div>
        </div>

        <div className="about3__box">
            <div className="about3__img2"></div>
        </div>

        <div className="about3__box">
            <img src={Cisco} alt="" />
        </div>

        <div className="about3__box">
            <div className="about3__img4"></div>
        </div>

        <div className="about3__box">
            <div className="about3__img5"></div>
        </div>

        <div className="about3__box">
            <img src={Hitachi} alt=""  />
        </div>

        <div className="about3__box">
            <div className="about3__img6"></div>
        </div>

        <div className="about3__box">
            <div className="about3__img3"></div>
        </div>

        <div className="about3__box">
            <img src={SM} alt=""  />
        </div>

        <div className="about3__box">
            <div className="about3__img7"></div>
        </div>

        <div className="about3__box">
            <img src={SO} alt=""  />
        </div>

        <div className="about3__box">
            <div className="about3__img8"></div>
        </div>

    </div>
  )
}

export default Info